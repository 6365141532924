import React, { useState, useEffect } from "react"; //Reactを読み込んでいる
import Fotter from "./parts/fotter";
import TicketCard from "./parts/ticketCard";
import { useLocation } from "react-router-dom";
import "../styles/tmp.css";
import axios from "axios";
import Button from '@material-ui/core/Button';
import { GoogleMap, LoadScript } from "@react-google-maps/api";

const GMAPAPIKEY = "AIzaSyAdtavrxWFKxmccGwcY8tniZXuEJjgDyQk";

let ticketLists = (ticketList) => {
    let items = [];
    for (let i = 0; i < ticketList.length; i++) {
        items.push(
            <TicketCard content={ticketList[i]} appendIcon></TicketCard>
        );
    }
    return <div style={{ marginBottom: 70 }}>{items}</div>;
};
const GoogleMapPage = (props) => {
    const { token } = useLocation();
    let [ticketList, setTicketList] = useState([]);
    const center = {
        lat: 35.69575,
        lng: 139.77521,
    };
    const [selectedLatLng, setSelectedLatLng] = useState(center);

    const handleClick = (e) => {
        setSelectedLatLng({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    };

    // useEffect(() => {
    //     const headers = {
    //         headers: {
    //             Authorization: "Bearer " + token,
    //             "Content-Type": "application/json",
    //         },
    //     };
    //     console.log(headers);
    //     axios.get("https://boost.cs29.eu.org/users/coupons", headers)
    //         .then((response) => {
    //             setTicketList(response.data);
    //             setTicketList(["demo", "demo"]);
    //         })
    //         .catch((error) => console.log(error));
    // }, []);

    const containerStyle = {
        width: "400px",
        height: "400px",
    };

    useEffect(() => {
        console.log(center);
    }, [center]);

    const sendData = () => {
        const requset = {
            "location": [
                selectedLatLng.lat,
                selectedLatLng.lng
            ],
            "radius": 1000
        }
        console.log(requset)
        const headers = {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        }
        console.log(headers)
        props.history.push({ pathname: "/page2" })
        // axios.post('https://boost.cs29.eu.org/places/food',requset,headers)
        //     .then(response => {
        //         console.log(response.data)
        //         props.history.push({ pathname: "/page2" })

        //         // 'https://boost.cs29.eu.org/placephoto/'
        //         // axios.get('https://boost.cs29.eu.org/placedetails/' + response.data[0].ref_id)
        //         //     .then(response => {
        //         //         console.log(response)
        //         //     })
        //         //     .catch(error => console.log(error))
        //     })
        //     .catch(error => console.log(error))
    };
    
    return (
        <div>
            <LoadScript googleMapsApiKey="AIzaSyAdtavrxWFKxmccGwcY8tniZXuEJjgDyQk">
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={17}
                    onClick={handleClick}
                ></GoogleMap>
            </LoadScript>
            <div>
                現在の緯度：{selectedLatLng.lat}・経度：{selectedLatLng.lng}
            </div>
            <Button
                variant="contained"
                style={{
                    position: "absolute",
                    backgroundColor: "#6E0F0F",
                    top: "707px",
                    left: "120px",
                    color: "#FFF",
                    width: "150px",
                    height: "52px",
                    borderRadius: "26px"
                }}
                onClick={sendData}>位置を決定</Button>
            <Fotter token={token} />
        </div>
    );
};

export default GoogleMapPage;
