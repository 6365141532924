import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import login from "./components/login";
import home from "./components/home";
import page1 from "./components/page1";
import page2 from "./components/page2";
import page3 from "./components/page3";
import page4 from "./components/page4";
import test from "./components/test";
import GoogleMapPage from "./components/googleMap";

const App = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={login} />
                <Route exact path="/home" component={home} />
                <Route exact path="/page1" component={page1} />
                <Route exact path="/page2" component={page2} />
                <Route exact path="/page3" component={page3} />
                <Route exact path="/page4" component={page4} />
                <Route exact path="/googleMapPage" component={GoogleMapPage} />
                <Route exact path="/develop" component={test} />
            </Switch>
        </BrowserRouter>
    );
};
export default App;
