import React, {useState, useEffect} from 'react'           //Reactを読み込んでいる
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Fab from '@material-ui/core/Fab';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import '../styles/tmp.css';
import { useLocation } from "react-router-dom"
import axios from 'axios'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
            <div
            style={{
                position: 'absolute',
                top: '464px',
                width: '390px',
                height: '',
            }}>
                
                {children}
            </div>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  

const Page1 = () => {
    const { state, token } = useLocation()
    const [value, setValue] = useState(0);
    const [valuePhotoRef, setValuePhotoRef] = useState('');
    const [valuePhoto, setValuePhoto] = useState([]);
    const [valueMessage, setValueMessage] = useState([]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        console.log(state)
        axios.get('https://boost.cs29.eu.org/placedetails/' + state)
            .then(response => {
                setValuePhoto([...response.data.photo_refs])
                setValueMessage([...response.data.reviews])
            })
            .catch(error => console.log(error))
    }, [])
    
    const theme = useTheme();

    const karidata = [
        {
            "id": 0, "datas":["赤から鍋 渋谷店", "aka.jpg"]
        },
        {
            "id": 1, "datas":["なんだかとっても長い店名", "aka.jpg"]
        },
        {
            "id": 2, "datas":["ねこのてまり", "aka.jpg"]
        },
        {
            "id": 3, "datas":["小籠包専門店", "aka.jpg"]
        },
        {
            "id": 0, "datas":["赤から鍋 渋谷店", "aka.jpg"]
        },
        {
            "id": 1, "datas":["なんだかとっても長い店名", "aka.jpg"]
        },
        {
            "id": 2, "datas":["ねこのてまり", "aka.jpg"]
        },
        {
            "id": 3, "datas":["小籠包専門店", "aka.jpg"]
        }
    ]
    const karicmt = [
        "辛さ3で結構辛いです",
        "せせりが美味しかった！",
        "商品の提供が遅いように感じましたが、味は良かった。空いているときに行きたい。",
        "隣の客がうるさかった。若者が多い印象。"
    ]

    const img_listcards = valuePhoto.map((data) =>
        <div className="imgcard">
            <img src={'https://boost.cs29.eu.org/placephoto/' + data}></img>
        </div>
    );
    const cmt_listcards = valueMessage.map((data) =>
        <div className="cmtcard">
            <div className='cmt'>{data.content}</div>
        </div>
    );

    return (
        <div className="hull-page">
            <div className="header">
                <Fab
                    component={Link}
                    style={{ backgroundColor: '#6E0F0F', color: 'white', margin: 15 }}
                    to={{ pathname: '/home', token: token}}
                >
                    <ArrowLeftIcon fontSize='large'/>
                </Fab>
            </div>
            <div className='placetitle'>お店の名前</div>
            <img className="topimg" src={'https://boost.cs29.eu.org/placephoto/' + valuePhoto[0]} width="147px" height="70px"></img>
            <Paper square>
                <Tabs
                    value={value}
                    variant="fullWidth"
                    onChange={handleChange}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#6E0F0F',
                            height: '6px',
                        }
                    }}
                    style={{
                        backgroundColor: 'white', 
                        color: '#6E0F0F', 
                        position: 'absolute',
                        top : '416px',
                        width: '390px',
                        height: '42px'
                    }}
                >
                    <Tab label="画像" />
                    <Tab label="コメント" />
                </Tabs>
            </Paper>
            <TabPanel value={value} index={0} dir={theme.direction} >
                <div class="user-wrapper">
                    <div>
                        {img_listcards}
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                <div class="user-wrapper">
                    <div>
                        {cmt_listcards}
                    </div>
                </div>
            </TabPanel>
        </div>
    );
}
export default Page1; 


