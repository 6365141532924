import React, {useState, useEffect} from 'react'
import axios from 'axios'

const Test = () => {
    let [imageData, setImageData] = useState("init")

    useEffect(() => {
        const requset = {
            "location": [
                34.726,
                135.236
            ],
            "radius": 1000
        }
        axios.post('https://boost.cs29.eu.org/places',requset)
            .then(response => {
                setImageData('https://boost.cs29.eu.org/placephoto/' + response.data[0].photo_ref)
                axios.get('https://boost.cs29.eu.org/placedetails/' + response.data[0].ref_id)
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => console.log(error))
            })
            .catch(error => console.log(error))
    }, [])
    
    return (
        <div>
            <img src={imageData} alt="サンプル" align="top"></img>
        </div>
    );
}
export default Test; 


