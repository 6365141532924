import React, {useState, useEffect} from 'react'  //Reactを読み込んでいる
import Fotter from './parts/fotter'
import TicketCard from './parts/ticketCard'
import { useLocation } from "react-router-dom"
import '../styles/tmp.css';
import axios from 'axios'

let ticketLists = (ticketList) => {
    let items = [];
    for (let i = 0; i < ticketList.length; i++) {
        items.push(
            <TicketCard
                content={ticketList[i]}
                appendIcon
            ></TicketCard>
        )
    }
    return <div style={{marginBottom: 70}}>{items}</div>;
}
const Page3 = () => {
    const { token } = useLocation()
    let [ticketList, setTicketList] = useState([])
    
    useEffect(() => {
        const headers = {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        }
        console.log(headers)
        axios.get('https://boost.cs29.eu.org/users/coupons',headers)
            .then(response => {
                setTicketList(response.data)
                setTicketList(["交通機関15%割引クーポン","飲食店10%割引クーポン","飲食店20%割引クーポン"])
            })
            .catch(error => console.log(error))
    }, [])
    return (
        <div className="hull-page" style={{display: 'flex', flexDirection: 'column'}}>
            <div className="header"></div>
            <h2>クーポン</h2>
            <div className="wrapper-vertical">
                {ticketLists(ticketList)}
            </div>
            <Fotter token={token} />
        </div>
    );
}

export default Page3; 