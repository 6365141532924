import React, {useState, useEffect} from 'react'
import axios from 'axios'
import '../styles/tmp.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const Login = (props) => {
    const [id, setId] = useState("");
    const [value, setValue] = useState("");
    let makeId = () =>{
        axios.get('https://boost.cs29.eu.org/login/0')
            .then(response => {
                console.log(response)
                setValue(response.data.access_token)
                localStorage.setItem('saved_account', response.data.access_token)
            })
            .catch(error => console.log(error))
    }
    function loginId(){
        if(!value) return
        axios.get('https://boost.cs29.eu.org/login/'+ value)
            .then(response => {
                console.log(response)
                setId(response.data.access_token)
                props.history.push({ pathname: "/home", token: value })
            })
            .catch(error => console.log(error))
    }
    useEffect(() => {
        let saved_account = localStorage.getItem('saved_account');
        if(saved_account) setValue(saved_account)
    }, [])
    return (
        <div className="hull-page-top">
            <img className="apptitle-img" src="appname.png"></img>
            <TextField value={ value } variant="filled" label="ユーザーidを入力" 
            style={{
                position: 'absolute',
                top: '456px',
                left: '46px',
                width: '302px',
                height: '56px',
                backgroundColor: '#FCF0F0',
                borderRadius: '16px',
                // backgroundColor: 'white'
                }} onChange={(event) => setValue(event.target.value)}>

            </TextField>
            <Button 
            style={{
                position: 'absolute',
                top: '530px',
                left: '87px',
                width: '220px',
                height: '55px',
                backgroundColor: 'white', 
                borderRadius: '28px',
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#6E0F0F'
            }}
            onClick={loginId}>ログイン</Button>
            <Button 
            variant="outlined"
            color='white'
            style={{
                borderColor: 'white',
                position: 'absolute',
                top: '671px',
                left: '87px',
                width: '220px',
                height: '55px',
                color: 'white',
                borderRadius: '28px',
                }} onClick={makeId}>新規作成</Button>
            {/* {id} */}
        </div>
    );
}

export default Login; 