import React, { useState, useEffect } from "react"; //Reactを読み込んでいる
import Fotter from "./parts/fotter";
import TicketCard from "./parts/ticketCard";
import { useLocation } from "react-router-dom";
import "../styles/tmp.css";
import axios from "axios";
import { FaMapMarkerAlt } from "react-icons/fa";

let ticketLists = (ticketList) => {
    let items = [];
    console.log(ticketList)
    for (let i = 0; i < ticketList.length; i++) {
        if (2 < ticketList[i].from_days && ticketList[i].from_days < 6) {
            items.push(
                <TicketCard
                    title={ticketList[i].title}
                    content={ticketList[i].content}
                    days={ticketList[i].from_days}
                ></TicketCard>
            )
        }
    }
    return <div style={{ marginBottom: 70 }}>{items}</div>;
};
const Page2 = (props) => {
    const { token } = useLocation();
    let [ticketList, setTicketList] = useState([]);

    useEffect(() => {
        const headers = {
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        };
        console.log(headers);
        axios.get('https://boost.cs29.eu.org/users/missions',headers)
            .then(response => {
                console.log(response)
                setTicketList(response.data)
                // setTicketList([{
                //     "title": "string",
                //     "content": "string",
                //     "mission_type": 0,
                //     "target_coupon_ref_id": "string",
                //     "from_days": 0
                // },{
                //     "title": "string",
                //     "content": "string",
                //     "mission_type": 0,
                //     "target_coupon_ref_id": "string",
                //     "from_days": 0
                // },{
                //     "title": "string",
                //     "content": "string",
                //     "mission_type": 0,
                //     "target_coupon_ref_id": "string",
                //     "from_days": 0
                // }])
            })
            .catch((error) => console.log(error));
    }, []);

    const mapIconClick = () => {
        props.history.push({ pathname: "/googleMapPage", token: token })
    };

    return (
        <div
            className="hull-page"
            style={{ display: "flex", flexDirection: "column" }}
        >
            <div className="header">
                <FaMapMarkerAlt
                    size={50}
                    style={{ margin: "10px" }}
                    onClick={mapIconClick}
                ></FaMapMarkerAlt>
            </div>
            <h2>ミッション</h2>
            <div className="wrapper-vertical">
                {ticketLists(ticketList)}
            </div>
            <Fotter token={token} />
        </div>
    );
};

export default Page2;
