import React from 'react';              //Reactを読み込んでいる
import Fotter from './parts/fotter'

import { useLocation } from "react-router-dom"
import '../styles/tmp.css';
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";

import Button from '@mui/material/Button';


const Page4 = () => {
    

    const { token } = useLocation()
    return (
        <div className="hull-page" style={{display: 'flex', flexDirection: 'column'}}>
            <div className="header"></div>
            <h2>口コミ投稿</h2>
            <div className="wrapper-vertical">
                <Autocomplete
                    options={[{ label: "Example1" }, { label: "ExampleExample2" }]}
                    renderInput={(params) => <TextField {...params} label="場所を入力" />}
                    style={{
                        position: "absolute",
                        backgroundColor: "#FFF",
                        top: "168px",
                        left: "46px",
                        color: "#CCCCCC",
                        width: "299px",
                    }}
                />
                
                <TextField
                    id="outlined-multiline-static"
                    label="コメントを入力"
                    multiline
                    rows={5}
                    style={{
                        position: "absolute",
                        backgroundColor: "#FFF",
                        top: "271px",
                        left: "46px",
                        color: "#CCCCCC",
                        width: "299px",
                    }}
                />
                <input className="imginput" type="file" accept="image/*" />
                <Button variant="contained"
                    style={{
                        position: "absolute",
                        backgroundColor: "#6E0F0F",
                        top: "707px",
                        left: "120px",
                        color: "#FFF",
                        width: "150px",
                        height: "52px",
                        borderRadius: "26px"
                    }}
                >投稿する</Button>

            </div>
            <Fotter token={token} />
        </div>
    );
}

export default Page4; 