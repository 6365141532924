import React, {useState, useEffect} from 'react'            //Reactを読み込んでいる
import Fotter from './parts/fotter'
import '../styles/tmp.css'
import PrimarySearchAppBar from './parts/search'
import { useLocation } from "react-router-dom"
import axios from 'axios'
import { hexToRgb } from '@material-ui/core'


const Home = (props) => {   //page1クラスにReact.Componentを継承する
    const { token } = useLocation()
    let [imageData, setImageData] = useState([])
    let [imageDataToor, setImageDataToor] = useState([])

    useEffect(() => {
        const requset = {
            "location": [
                34.726,
                135.236
            ],
            "radius": 1000
        }
        const headers = {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        }
        axios.post('https://boost.cs29.eu.org/places/food',requset,headers)
            .then(response => {
                console.log(response.data)
                setImageData(response.data)
                // 'https://boost.cs29.eu.org/placephoto/'
                // axios.get('https://boost.cs29.eu.org/placedetails/' + response.data[0].ref_id)
                //     .then(response => {
                //         console.log(response)
                //     })
                //     .catch(error => console.log(error))
            })
            .catch(error => console.log(error))
        axios.post('https://boost.cs29.eu.org/places/tourist_attraction',requset,headers)
            .then(response => {
                console.log(response.data)
                setImageDataToor(response.data)
                // axios.get('https://boost.cs29.eu.org/placedetails/' + response.data[0].ref_id)
                //     .then(response => {
                //         console.log(response)
                //     })
                //     .catch(error => console.log(error))
            })
            .catch(error => console.log(error))
    }, [])
    const karidata = [
        {
            "id": 0, "datas":["赤から鍋 渋谷店", "aka.jpg"]
        },
        {
            "id": 1, "datas":["なんだかとっても長い店名", "aka.jpg"]
        },
        {
            "id": 2, "datas":["ねこのてまり", "aka.jpg"]
        },
        {
            "id": 3, "datas":["小籠包専門店", "aka.jpg"]
        }
    ]

    const cat1_listcards = [...imageData].map((data) =>
        <div
            className="listcard"
            onClick={()=>{transition(data)}}
        >
            <img src={'https://boost.cs29.eu.org/placephoto/' + data.photo_ref} width="147px" height="70px"></img>
            <div className='name-box'>
                <div className='name'>{data.name}</div>
            </div>
        </div>
    );

    let transition = (data) => {
        console.log("before," ,data.ref_id)
        props.history.push({ pathname: "/page1", state: data.ref_id, token: token})
    }

    const cat2_listcards  = [...imageDataToor].map((data) =>
        <div
            className="listcard"
            onClick={()=>{transition(data)}}
        >
            <img src={'https://boost.cs29.eu.org/placephoto/' + data.photo_ref} width="147px" height="70px"></img>
            <div className='name-box'>
                <div className='name'>{data.name}</div>
            </div>
        </div>
    );

    return (
        <div className="hull-page">
            <PrimarySearchAppBar />
            <h1>水戸市</h1>
            <div className='cat1'>飲食店</div>
            <div class="wrapper wrap1">
                <div className="scroll-list">
                    {cat1_listcards}
                </div>
            </div>

            <div className='cat2'>観光</div>
            <div class="wrapper wrap2">
                <div className="scroll-list">
                    {cat2_listcards}
                </div>
            </div>
            <Fotter token={token} />
        </div>
    );
}
export default Home; 


