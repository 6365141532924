import React, {useState, useEffect} from 'react'
import '../../styles/tmp.css';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';


const useStyles = theme => ({
  root: {
    width: 336,
    height: 123
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
  },
  pos: {
    marginBottom: 12,
  },
});

class TicketCard extends React.Component {
    render() {
      const { classes } = this.props;
      return (
        <Card className={classes.root} style={{ display: 'flex', margin: 27, borderRadius: 20}}>
          <CardContent style={{ width: 250 }}>
              <div className="mission-name">{this.props.title}</div>
              <div >{this.props.content}</div>
            <div className=''>
              {(() => {
                  if (!this.props.appendIcon) {
                    return "残り日数"
                  }
              })()}
              
              {this.props.days}</div>
                {(() => {
                    if (!this.props.appendIcon) {
                      return <Checkbox style={{top: '70px', left: '215px'}}></Checkbox>
                    }
                })()}
            </CardContent>
            <CardActions style={{ width: 40,backgroundColor: '#6E0F0F'}} >
                  {(() => {
                    if (this.props.appendIcon) {
                        return <ArrowRightIcon
                            style={{ fontSize: "40", color: 'white' }}
                        />
                    }
                })()}
            </CardActions>
        </Card>
      );
    }
}

export default withStyles(useStyles)(TicketCard);