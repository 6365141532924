import React from 'react';
import '../../styles/tmp.css';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home';

class Foot extends React.Component {
    render() {
        return (
            <div className="navi">
                <div className="iconbox">
                    <IconButton
                        component={Link}
                        to={{ pathname: '/home', token: this.props.token }}
                    >
                        <HomeIcon
                            size="30px"
                            style={{ color: "white" }}
                        />
                    </IconButton>
                </div>
                {/* <div className="iconbox">
                    <IconButton
                        component={Link}
                        to={{ pathname: '/page1', token: this.props.token }}
                    >
                        <FavoriteIcon
                            size="30px"
                            style={{ color: "white" }}
                        />
                    </IconButton>
                </div> */}
                <div className="iconbox">
                    <IconButton
                        component={Link}
                        to={{ pathname: '/page2', token: this.props.token }}
                    >
                        <PlaylistAddCheckIcon
                            size="30px"
                            style={{ color: "white" }}
                        />
                    </IconButton>
                </div>
                <div className="iconbox">
                    <IconButton
                        component={Link}
                        to={{ pathname: '/page3', token: this.props.token }}
                    >
                        <ConfirmationNumberIcon
                            size="30px"
                            style={{ color: "white" }}
                        />
                    </IconButton>
                </div>
                <div className="iconbox">
                    <IconButton
                        component={Link}
                        to={{ pathname: '/page4', token: this.props.token }}
                    >
                        <BorderColorIcon
                            size="30px"
                            style={{ color: "white" }}
                        />
                    </IconButton>
                </div>
                
            </div>
        );
    }
}

export default Foot;